<template>
  <div id="app">
    <Nav />
    <div class="mt-50">
      <img class="top-img" :src="require(`@/assets/banner/banner-01${$i18n.locale == 'zh'?'z':'e'}.png`)" alt="" />
      <div class="pubilc-top flex between" :class="$i18n.locale == 'en' ?'en-pubilc-top':''">
        <div
          class="pubilc-hz"
          v-for="(item, index) in topList"
          :key="index"
          :class="type == item.id ? 'active' : ''"
          @click="concert(item)"
        >
          {{ $i18n.locale == 'zh' ?item.name:item.name2 }}
        </div>
      </div>
      <!-- 标签 -->
      <div class="artTraining-title flex center mb-10">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">{{ title }}</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-29.png"
            alt=""
            v-if="type==0"
          />
           <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-30.png"
            alt=""
            v-else-if="type==1"
          />
           <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-31.png"
            alt=""
            v-else-if="type==2"
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <p class="pubilc-p1 color1 f-12" v-if="type==0">
        {{ $t("publicBenefit.concert.text2") }}
      </p>
       <p class="pubilc-p1 color1 f-12" v-else-if="type==1">
          {{ $t("publicBenefit.forum.text2") }}
       </p>
       <p class="pubilc-p1 color1 f-12" v-else-if="type==2">
          {{ $t("publicBenefit.school.text2") }}
       </p>
      <!-- 内容 -->
      <div class="pubilc-ul">
        <div class="pubilc-li flex" v-for="item2 in brtList" :key="item2.id" @click="gotoDetail(item2)">
          <img class="pubilc-li-img" :src="`${$baseUrl}${item2.thumb}`" alt="" />
          <div class="pubilc-li-hz">
            <p class="pubilc-li-p1 ellipsis">{{item2.title}}</p>
            <p class="pubilc-li-p2 ellipsis3 color3">
              {{item2.description}}
            </p>
            <p class="color3 f-12">{{__commonJs['happenTimeFun'](item2.inputtime * 1000,'ymd')}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="pagination">
          <el-pagination :hide-on-single-page="true" @current-change="handleSizeChange" small layout="prev, pager, next" :total="12">
      </el-pagination>
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<style scoped></style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      type: 0,
      title: "中型音乐会",
      topList: [
        {
          id: 0,
          name: "中型音乐会",
          name2:"The Medium Scale Concert"
        },
        {
          id: 1,
          name: "导赏音乐会",
          name2:'Guided concert'
        },
        {
          id: 2,
          name: "进社区进校园",
          name2:"Concert Entering School & Community"
        },
      ],
      brtList:[],
    };
  },
  computed: {},
  created() {
    this.type = this.$route.query.type || 0;
    this.getBrt()
    let val = this.topList[this.type]
    this.concert(val)
  },
  mouthed() {},
  methods: {
    concert(val) {
      this.type = val.id;
      if(this.$i18n.locale == 'zh'){
        this.title = val.name;
      }else{
        this.title = val.name2;
      }
      this.getBrt()
    },
    handleSizeChange(val){
        console.log(96,val)
    },
     getBrt() {
      // 获取列表
      this.$ajax.get("/cms/api/huiminlist", {gtype: this.type,}).then((res) => {
        if (res.code == "200") {
          this.brtList = res.data
        }
      });

    },
    gotoDetail(item) {
      this.$router.push({ name: "NewsView", params: { id: item.id }, query: {type: 'pinpaigongyi'} });
    },
  },
};
</script>


<style lang="scss">
.pagination{
    text-align: center;
    margin:10px 0 30px;
    .el-pagination button:hover{
        color: #835C46!important;
    }   
    .btn-prev{
        background-color: transparent!important;
    }
    .el-pager{
        li{
           background-color: transparent!important;
           color: rgba(0,0,0,0.6);
        }
        .active{
            color: #835C46!important;
        }
    }
    .btn-next{
        background-color: transparent!important;
    }
}
</style>
<style lang="scss" scoped>
#app {
  background-color: #f2f6f7;
}
.en-pubilc-top{
  .pubilc-hz{
    font-size: 10px!important;
    line-height: 12px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
  }
}
.pubilc-top {
  padding: 24px 12px;
  .pubilc-hz {
    width: 30%;
    height: 36px;
    border: 1px solid #835c46;
    color: #634636;
    font-size: 16px;
    text-align: center;
    line-height: 36px;
    border-radius: 20px 0 20px 0;
  }
  .active {
    background-color: #835c46;
    color: #fff;
  }
}
.artTraining-title {
  height: 25px;
  overflow: hidden;
  .artTraining-beam1 {
    width: 20px;
    height: 1px;
    background-color: #b3b3b3;
  }
  .artTraining-beam2 {
    width: 4px;
    height: 25px;
    background-color: #b49a71;
  }
  .artTraining-xhz {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
    position: relative;
    height: 25px;
    .artTraining-xhz-p {
      line-height: 18px;
    }
    .artTraining-xhz-img {
      height: 10px;
      position: absolute;
      // top: -9px;
      bottom:0;
    }
  }
  .artTraining-beam3 {
    flex: 1;
    height: 1px;
    background-color: #b3b3b3;
    margin-left: 14px;
  }
}
.pubilc-p1 {
  padding: 0 9px 0 28px;
  line-height: 18px;
}
.pubilc-ul {
  padding: 24px 12px 0;
  .pubilc-li {
    width: 100%;
    height: 112px;
    background-color: #fff;
    margin-bottom: 16px;
    .pubilc-li-img {
      width: 53.5%;
      height: 100%;
    }
    .pubilc-li-hz {
      width: 46.5%;
      padding: 9px 0 4px 8px;
      box-sizing: border-box;
      .pubilc-li-p1 {
        color: #2b3033;
        font-size: 14px;
        line-height: 21px;
        height: 21px;
        overflow: hidden;
        font-weight: 400;
      }
      .pubilc-li-p2 {
        font-size: 12px;
        line-height: 15px;
        height: 45px;
        overflow: hidden;
        margin: 5px 0 6px;
      }
    }
  }
}
</style>

